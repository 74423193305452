import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Un format intensiu de 8 hores en un sol dia.</strong> El curs
                    s'imparteix el dissabte 12 d'octubre, de 9.00 a 13.00 h i de 15.00 a 18.00 h. Al
                    curs ens centrarem en els conceptes més importants i susceptibles de sortir a
                    l'examen, amb exercicis basats en anteriors convocatòries i nous exercicis
                    elaborats pel nostre equip de psicòlegs, incloent els famosos puzles i sèries de
                    boletes, entre d'altres.
                </p>

                <p>
                    A <strong>iOpos Acadèmia</strong>, tenim la nostra pròpia metodologia: us
                    ensenyarem trucs exclusius, us donarem explicacions detallades de cada temàtica
                    i tips per anar molt ràpid. És un dels nostres cursos estrella i estem segurs
                    que, un cop fet el curs, canviaràs la teva visió dels psicotècnics. Cada alumne
                    tindrà un dossier específic de franc totalment nou.
                </p>

                <p>
                    La formació es realitza al{" "}
                    <strong>c/Martí Codolar núm. 18, Hospitalet de Llobregat</strong>. T'esperem!
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/Kk3Rl3St6Qs"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Et presentem una nova edició renovada del millor curs per
                    preparar els psicotècnics per Mossos d'Esquadra, policies
                    locals i Guàrdia Urbana!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
